import { StatisticsBox } from "./StatisticsBox"
import { toPercent } from "../../utils"
import { useMemo } from "react"
import { IEventCount } from "@today/api/tracker"
import { EventCount, EventWindow } from "./models"
import { mergeWindows } from "./Window"

type DateRangeInfoProps = {
  dailyWindows: EventWindow[]
  eventTimeWindows: EventWindow[]
}

function getBackgroundColor(
  ec: EventCount,
  k: Exclude<keyof EventCount, keyof IEventCount>
) {
  const v = ec[k]
  switch (k) {
    case "deliveredRatio":
    case "deliveredInTimeRatio":
      return v > 0.99 ? "success" : v > 0.9 ? "warning" : "danger"
    case "lostRatio":
    case "quitRatio":
      return v > 0.005 ? "danger" : v > 0.001 ? "warning" : "success"
    case "holdRatio":
      return v > 0.01 ? "danger" : v > 0.005 ? "warning" : "success"
  }
}

export function DateRangeInfo({
  dailyWindows,
  eventTimeWindows,
}: DateRangeInfoProps) {
  const [avgDaily, minDaily, maxDaily, sumDaily] = useMemo(() => {
    const filteredWindows = dailyWindows.filter(
      (eventWindow) => !eventWindow.isEmpty()
    )
    return [
      EventWindow.avg(filteredWindows),
      EventWindow.min(filteredWindows, "pickUpCount"),
      EventWindow.max(filteredWindows, "pickUpCount"),
      EventWindow.merge(filteredWindows),
    ]
  }, [dailyWindows])
  const [avgEventTime, minEventTime, maxEventTime, sumEventTime] =
    useMemo(() => {
      const filteredWindows = mergeWindows(
        eventTimeWindows.filter((eventWindow) => !eventWindow.isEmpty()),
        3600 * 24
      )
      return [
        EventWindow.avg(filteredWindows),
        EventWindow.min(filteredWindows, "totalDeliverTargetCount"),
        EventWindow.max(filteredWindows, "totalDeliverTargetCount"),
        EventWindow.merge(filteredWindows),
      ]
    }, [eventTimeWindows])
  return (
    <>
      <div className="mt-4 grid grid-cols-7 gap-x-2">
        <StatisticsBox
          title="총 집화 물품"
          className="col-span-2"
          bg={sumEventTime.pickUpCount > 0 ? "success" : undefined}
        >
          <p>총 {sumEventTime.pickUpCount.toLocaleString()}건</p>
          <p className="mt-2 text-center text-sm text-gray-500">
            기간 내 집화된 물품 수
          </p>
        </StatisticsBox>
        <StatisticsBox
          title="배송 보류 물품"
          bg={getBackgroundColor(sumDaily, "holdRatio")}
        >
          <p>{sumDaily.holdDeliveryCount.toLocaleString()}건</p>
          <p className="mt-2 text-center text-sm text-gray-500">
            {toPercent(sumDaily.holdRatio, 2)}
          </p>
        </StatisticsBox>
        <StatisticsBox
          title="배송 중단 물품"
          bg={getBackgroundColor(sumDaily, "quitRatio")}
        >
          <p>{sumDaily.quitCount.toLocaleString()}건</p>
          <p className="mt-2 text-center text-sm text-gray-500">
            {toPercent(sumDaily.quitRatio, 3)}
          </p>
        </StatisticsBox>
        <StatisticsBox
          title="분실 물품"
          bg={getBackgroundColor(sumDaily, "lostRatio")}
        >
          <p>{sumDaily.lostCount.toLocaleString()}건</p>
          <p className="mt-2 text-center text-sm text-gray-500">
            {toPercent(sumDaily.lostRatio, 3)}
          </p>
        </StatisticsBox>
        <StatisticsBox title="일별 집화 물품" className="col-span-2">
          <p>
            평균{" "}
            {avgEventTime.pickUpCount.toLocaleString("ko-KR", {
              maximumFractionDigits: 1,
            })}
            건
          </p>
          <p className="mt-2 text-center text-sm text-gray-500">
            {minEventTime.pickUpCount.toLocaleString()}건 ~{" "}
            {maxEventTime.pickUpCount.toLocaleString()}건
          </p>
        </StatisticsBox>
      </div>
      <div className="mt-4 grid grid-cols-7 gap-x-2">
        <StatisticsBox
          title="기간 내 배송 대상"
          className="col-span-2"
          bg={sumDaily.totalDeliverTargetCount > 0 ? "success" : undefined}
        >
          <p>총 {sumDaily.totalDeliverTargetCount.toLocaleString()}건</p>
        </StatisticsBox>
        <StatisticsBox
          title="총 배송 완료율"
          bg={getBackgroundColor(sumDaily, "deliveredRatio")}
        >
          <p>{toPercent(Math.min(1, sumDaily.deliveredRatio))}</p>
          <p className="mt-2 text-center text-sm text-gray-500">
            {sumDaily.deliveredCount.toLocaleString()}건
          </p>
        </StatisticsBox>
        <StatisticsBox
          title="총 SLI 준수율"
          bg={getBackgroundColor(sumDaily, "deliveredInTimeRatio")}
        >
          <p>{toPercent(Math.min(1, sumDaily.deliveredInTimeRatio))}</p>
          <p className="mt-2 text-center text-sm text-gray-500">
            {sumDaily.deliveredInTimeCount.toLocaleString()}건
          </p>
        </StatisticsBox>
        <StatisticsBox
          title="SLI 달성 실패 총합"
          bg={getBackgroundColor(sumDaily, "deliveredInTimeRatio")}
        >
          <p>
            {(
              sumDaily.deliveredCount - sumDaily.deliveredInTimeCount
            ).toLocaleString()}
            건
          </p>
        </StatisticsBox>
        <>
          <StatisticsBox
            title="일별 배송 완료율"
            bg={getBackgroundColor(avgDaily, "deliveredRatio")}
          >
            <p>평균 {toPercent(avgDaily.deliveredRatio)}</p>
            <p className="mt-2 text-center text-sm text-gray-500">
              {toPercent(Math.min(1, minDaily.deliveredRatio))} ~{" "}
              {toPercent(Math.min(1, maxDaily.deliveredRatio))}
            </p>
          </StatisticsBox>
          <StatisticsBox
            title="일별 SLI 준수율"
            bg={getBackgroundColor(avgDaily, "deliveredInTimeRatio")}
          >
            <p>평균 {toPercent(avgDaily.deliveredInTimeRatio)}</p>
            <p className="mt-2 text-center text-sm text-gray-500">
              {toPercent(Math.min(1, minDaily.deliveredInTimeRatio))} ~{" "}
              {toPercent(Math.min(1, maxDaily.deliveredInTimeRatio))}
            </p>
          </StatisticsBox>
        </>
      </div>
    </>
  )
}
