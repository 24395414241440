import { Button } from "baseui/button"
import { useState } from "react"
import { ButtonGroup, MODE, SIZE as BG_SIZE } from "baseui/button-group"

type DeliveryClassSelectorProps<T> = {
  onChange(items?: T[]): void
  availableOptions: T[]
  getLabel(item: T): string
}

export function ButtonGroupSelector<T>({
  onChange,
  availableOptions,
  getLabel,
}: DeliveryClassSelectorProps<T>) {
  const [selected, setSelected] = useState<number[]>([])
  return (
    <div className="flex items-center">
      <ButtonGroup
        mode={MODE.checkbox}
        size={BG_SIZE.mini}
        selected={selected}
        onClick={(_event, index) => {
          const newSelected = !selected.includes(index)
            ? [...selected, index]
            : selected.filter((value) => value !== index)
          setSelected(newSelected)
          onChange(
            newSelected.length
              ? newSelected.map((idx) => availableOptions[idx])
              : undefined
          )
        }}
      >
        {availableOptions.map((item) => (
          <Button key={`${item}`}>{getLabel(item)}</Button>
        ))}
      </ButtonGroup>
    </div>
  )
}
