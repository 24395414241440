import { StatisticsBox } from "./StatisticsBox"
import { toPercent } from "../../utils"
import { IStats } from "@today/api/tracker"
import { Stats } from "./models"

type RealTimeInfoProps = {
  targetDeliverTimeStats: Stats
  eventTimeStats: IStats
}

export function RealTimeInfo({
  targetDeliverTimeStats,
  eventTimeStats,
}: RealTimeInfoProps) {
  const bg =
    targetDeliverTimeStats.deliveredRatio > 0.99
      ? "success"
      : targetDeliverTimeStats.deliveredRatio > 0.9
      ? "warning"
      : "danger"
  const bgSLI =
    targetDeliverTimeStats.deliveredInTimeRatio > 0.99
      ? "success"
      : targetDeliverTimeStats.deliveredInTimeRatio > 0.9
      ? "warning"
      : "danger"
  return (
    <>
      <div className="mt-4 grid grid-cols-2 gap-2 md:grid-cols-7">
        <StatisticsBox
          title="오늘 집화"
          className="col-span-2"
          bg={eventTimeStats.pickUpCount > 0 ? "success" : undefined}
        >
          <p>총 {eventTimeStats.pickUpCount.toLocaleString()}건</p>
          <p className="mt-2 text-center text-sm text-gray-500">
            오늘 집화/인수가 이뤄진 물품 수 (내일도착 등 포함)
          </p>
        </StatisticsBox>
        <StatisticsBox title="집화 대기 중">
          <p>{eventTimeStats.pendingCollectCount.toLocaleString()}건</p>
          <p className="mt-2 text-center text-sm text-gray-500">
            최근 1주일 간 집화 대기 중인 물품 수
          </p>
        </StatisticsBox>
        <StatisticsBox title="집화 중">
          <p>{eventTimeStats.inCollectCount.toLocaleString()}건</p>
        </StatisticsBox>
        <StatisticsBox title="간선 운송 중">
          <p>{eventTimeStats.inTransportCount.toLocaleString()}건</p>
        </StatisticsBox>
        <StatisticsBox title="정류소 체류 중">
          <p>{eventTimeStats.atStationCount.toLocaleString()}건</p>
        </StatisticsBox>
        <StatisticsBox title="LM 배송 중">
          <p>{eventTimeStats.inDeliveryCount.toLocaleString()}건</p>
        </StatisticsBox>
      </div>
      <div className="mt-4 grid grid-cols-2 gap-2 md:grid-cols-7">
        <StatisticsBox
          title="오늘 내 배송 대상"
          className="col-span-2"
          bg={targetDeliverTimeStats.pickUpCount > 0 ? "success" : undefined}
        >
          <p>총 {targetDeliverTimeStats.pickUpCount.toLocaleString()}건</p>
          <p className="mt-2 text-center text-sm text-gray-500">
            오늘 SLI 달성 대상 픽업 처리된 물품 수
          </p>
        </StatisticsBox>
        <StatisticsBox title="배송 완료율" bg={bg}>
          <p>{toPercent(targetDeliverTimeStats.deliveredRatio)}</p>
          <p className="mt-2 text-center text-sm text-gray-500">
            총 {targetDeliverTimeStats.deliveredCount.toLocaleString()}건 배송
          </p>
        </StatisticsBox>
        <StatisticsBox title="SLI 준수율" bg={bgSLI}>
          <p>{toPercent(targetDeliverTimeStats.deliveredInTimeRatio)}</p>
          <p className="mt-2 text-center text-sm text-gray-500">
            총 {targetDeliverTimeStats.deliveredInTimeCount.toLocaleString()}건
            준수
          </p>
        </StatisticsBox>
        <StatisticsBox
          title="남은 배송"
          bg={
            targetDeliverTimeStats.remainingDeliveryCount === 0 ? "success" : bg
          }
        >
          <p>
            {targetDeliverTimeStats.remainingDeliveryCount.toLocaleString()}건
          </p>
        </StatisticsBox>
        <StatisticsBox
          title="보류 물품"
          bg={
            targetDeliverTimeStats.holdDeliveryCount +
              targetDeliverTimeStats.holdCollectCount >
            0
              ? "warning"
              : undefined
          }
        >
          <p>
            {(
              targetDeliverTimeStats.holdDeliveryCount +
              targetDeliverTimeStats.holdCollectCount
            ).toLocaleString()}
            건
          </p>
          <p className="mt-2 text-center text-sm text-gray-500">
            인수: {targetDeliverTimeStats.holdCollectCount.toLocaleString()}건 /
            배송: {targetDeliverTimeStats.holdDeliveryCount.toLocaleString()}건
          </p>
        </StatisticsBox>
        <StatisticsBox
          title="운송 중단"
          bg={
            targetDeliverTimeStats.quitCount +
              targetDeliverTimeStats.lostCount >
            0
              ? "danger"
              : undefined
          }
        >
          <p>
            {(
              targetDeliverTimeStats.quitCount +
              targetDeliverTimeStats.lostCount
            ).toLocaleString()}
            건
          </p>
          <p className="mt-2 text-center text-sm text-gray-500">
            배송사 귀책 한정 (분실 {targetDeliverTimeStats.lostCount}건)
          </p>
        </StatisticsBox>
      </div>
    </>
  )
}
