import React from "react"

type StatisticsBoxProps = {
  title: string
  bg?: "success" | "warning" | "danger"
  className?: string
}

function colorMap(c: StatisticsBoxProps["bg"]) {
  switch (c) {
    case "success":
      return "#fcfff5"
    case "warning":
      return "#fffaf3"
    case "danger":
      return "#fff6f6"
  }
  return null
}

export function StatisticsBox({
  title,
  bg,
  children,
  className,
}: React.PropsWithChildren<StatisticsBoxProps>) {
  const bgColor = colorMap(bg)
  return (
    <div
      className={
        "flex gap-x-2 rounded-lg border bg-white px-5 py-5 text-center " +
        (className ?? "")
      }
      style={bgColor ? { backgroundColor: bgColor } : {}}
    >
      <div className="mt-0.5 flex flex-1 flex-col items-center justify-center gap-y-1">
        <div className="mb-2 text-lg font-semibold">{title}</div>
        <div className="break-all text-center text-3xl text-[#0A2942]">
          {children}
        </div>
      </div>
    </div>
  )
}
