import { usePagination } from "@today/lib"
import { ListClientsResponse } from "@today/api/taker"
import { useEffect, useMemo, useState } from "react"
import { Value } from "baseui/select"
import { getClientOptions } from "../loads/LoadFilter"
import { useRouter } from "next/router"

const PAGE_SIZE = 100

export function useClientSelector(initialClientId: string | undefined) {
  const router = useRouter()
  const { data, isLoading } = usePagination<ListClientsResponse>(
    `/api/clients`,
    PAGE_SIZE,
    true
  )
  const [selectedClients, setSelectedClients] = useState<Value>(
    initialClientId ? [{ id: initialClientId }] : []
  )
  const clientOptions = useMemo(
    () =>
      data
        ? getClientOptions(data.flatMap(({ clients }) => clients))
        : undefined,
    [data]
  )
  const clientId = useMemo(
    () =>
      selectedClients.length === 0
        ? undefined
        : (selectedClients[0].id as string),
    [selectedClients]
  )
  useEffect(() => {
    if (clientId) {
      router.replace(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            clientId,
          },
        },
        undefined,
        {
          shallow: true,
        }
      )
    } else {
      const query = { ...router.query }
      delete query.clientId
      router.replace(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        {
          shallow: true,
        }
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId])
  return {
    clientId,
    clientOptions,
    selectedValue: selectedClients,
    setSelectedValue: setSelectedClients,
    isLoading,
  }
}
